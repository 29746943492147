// import { Routes, Route,BrowserRouter } from "react-router-dom";
// import axios from 'axios';
import { useEffect, useState } from 'react';
import '../static.css';

import chek from '../../../assets/chek.jpg.crdownload';

const Service = () => {
  return (
    <div className="static">
      <h2>Доставка и оплата</h2>
      <p>Заказы принимаются по 100% предварительной оплате</p>
      <h3>Доставка и оплата по Минску: </h3>
      <p>
        Доставка в пределах МКАД, Уручье и Копище
        <br />
        Стоимость доставки: 10 рублей если сумма вашего заказа менее 200,00
        рублей.
        <br />
        Оплата наличными курьеру. <br />
        Если ваш заказ превышает сумму в 200,00 рублей, то доставка за наш счёт
        (кроме крупногабаритных товаров).
        <br />
        Доставка за пределы МКАД возможна только после уточнения деталей с нашим
        менеджером и рассчитывается в каждом случае индивидуально.
      </p>
      <p>
        Убедительная просьба: перед выездом к нам в магазин, резервируйте товар
        по телефонам:
        <br />
        +375(29)577-46-70
        <br />
        +375(44)577-46-70
        <br />
        +375(25)777-46-70
      </p>

      <h3>Доставка заказов по Беларуси: </h3>
      <h3>
        <b>
          Хотим уведомить наших клиентов что мы не отправляем ТОПЛИВО почтой в
          регионы. Отправка ТОЛЬКО курьерской службой.
        </b>
      </h3>
      <p>
        Доставка Белпочтой:
        <br />
        Отправка Вашего заказа происходит только после его подтверждения в
        течение 2-3 рабочих дней. Заказы отправляются наложеным платежом, и
        оплачиваете заказ при получении на почте. Стоимость отправления вашего
        заказа напрямую зависит от веса и размера посылки. Примерную стоимость
        возможно будет уточнить у нашего менеджера. Точная стоимость будет
        известна только при отправке на самой почте. При получении на почте Вам
        необходимо будет оплатить сумму наложенного платежа + услуги почты: 2-5%
        в зависимости от стоимости заказа. Заказы стоимостью свыше 100,00 рублей
        или крупногабаритные заказы (модели) дешевле отправить курьером по
        Беларуси.{' '}
      </p>

      <h3>
        <b>Доставка курьером по Беларуси:</b>
      </h3>
      <p>
        Мы можем отправить Ваш заказ курьером с вручением прямо в руки в любой
        город и деревню Беларуси, сроки доставки на следующий день или через
        день (зависит от Вашего местоположения). Стоимость курьерской доставки
        от 17,00 до 50,00р (зависит от Вашего местоположения и габаритов Вашего
        заказа). Уточняем, что доставку осуществляет сторонняя курьерская
        служба, которая имеет некоторые условия: До квартиры доставляют грузы до
        15 кг и до 0,07м3, больший размер и вес только до подъезда. Курьерская
        доставка расчитывается нашими менеджерами, для большей информации
        позвоните нам +375(29)577-46-70 +375(44)577-46-70 +375(25)777-46-70{' '}
      </p>

      <h3>Способы оплаты:</h3>
      <p>
        1) Наличными в магазине
        <br />
        2) Безналичный расчет: банковские переводы, карта VISA, Master Card,
        Белкард (временно не принимается)
        <br />
        3) По платежному поручению в любом банке.
        <br />
        Для оформления платежного получения обращайтесь к нашим менеджерам.
        +375(29)577-46-70 +375(44)577-46-70 +375(25)777-46-70
        <br />
        Образцы документов, подтверждающих оплату
      </p>
      <img src={chek} alt="чек" />

      <h3>Гарантии и возврат</h3>
      <p>
        Основная цель нашей компании заключается в том, чтобы опыт вашего
        общения с нами был приятным и плодотворным с самого первого посещения
        нашего сайта до момента, когда вы будете наслаждаться долгожданной
        покупкой. Важно сделать практичный и гарантированно качественный продукт
        более доступным на рынке, но еще более важно, чтобы ваши впечатления от
        покупки остались исключительно положительными. Мы будем сопровождать вас
        на всем пути приобретения любой продукции из нашего ассортимента,
        радиоуправляемых моделей, автомоделей, авиамоделей, запасных частей,
        аксессуаров и всех сопутствующих товаров.
        <br />
        Вы будете чувствовать себя комфортно на протяжении всего процесса
        покупки.
        <br />
        Отправка товара на гарантийное обслуживание или возврат осуществляется
        за счет покупателя, согласно закону о защите прав потребителя.
        <br />
        На всю продукцию, представленную в нашем магазине, распространяется
        гарантийное обслуживание в течение 60 дней со дня приобретения. Гарантия
        на Возврат товара или его замена на новый возможна только при сохранении
        товарной упаковки (в случае если товар надлежащего качества) и внешнего
        вида товара (отсутствие следов износа, несоответствующего использования,
        повреждений) согласно п.3 ст.20 и п.2 ст.28 Закона Республики Беларусь о
        защите прав потребителей. Так же уточняем что возврат технически сложных
        товаров регламентируется Постановлением Совета Министров РБ № 778 от
        14.06.2002. О мерах по реализации Закона Республики Беларусь.
        <br />
        Гарантийный или не гарантийный сервис осуществляется только в нашем
        магазине , доставка изделий в сервис (и обратно клиенту) осуществляется
        исключительно за счет клиента.
      </p>

      <h3>Внимание!</h3>
      <p>
        В случае, если товар доставлен, но заказчик от него отказывается без
        уважительных причин (наличие дефекта или других свойств,
        свидетельствующих о том, что товар некачественный), клиент обязуется
        возместить магазину затраты, связанные с доставкой товара, согласно
        пункту 3 статьи 467 Гражданского кодекса РБ, а также Постановлению
        Совета Министров Республики Беларусь №31 от 15.01.2009 г п.16.
      </p>
    </div>
  );
};

export default Service;
