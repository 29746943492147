import React, { useEffect, useState } from 'react';
import remove from '../../assets/remove-img.png';
import './ImageUploader.css';

function ImageUploader({ imagesData = [], setConvertImg }) {
  const [images, setImages] = useState(imagesData);
  const [imagesFile, setImagesFile] = useState([]);

  useEffect(() => {
    const imagesId = images
      .filter((item) => item.id !== undefined)
      .map((item) => item.id);
    setConvertImg({ imageIDs: imagesId, images: imagesFile });
  }, [images, imagesFile]);

  const handleImageChange = (event) => {
    const files = Array.from(event.target.files);

    setImagesFile([...imagesFile, ...files]);
    setImages((prevImages) => [...prevImages, ...files]);
  };

  const handleRemoveImage = (index, isFile) => {
    setImages((prevImages) => prevImages.filter((_, i) => i !== index));
    if (isFile) {
      setImagesFile((prevImagesFile) =>
        prevImagesFile.filter((_, i) => i !== index)
      );
    }
  };

  return (
    <div>
      <h2>Загрузка картинок</h2>
      <input
        type="file"
        multiple
        accept="image/*"
        onChange={handleImageChange}
      />
      <div className="image-container">
        {images &&
          images.map((image, index) => (
            <div key={image.id} className="image-item">
              <img
                src={
                  image.url && image.url.slice(0, 4) === 'http'
                    ? image.url
                    : URL.createObjectURL(image)
                }
                alt="Uploaded Image"
              />
              {images.length > 1 && (
                <button
                  className="remove-img-btn"
                  onClick={() =>
                    handleRemoveImage(
                      index,
                      !image.url || image.url.slice(0, 4) !== 'http'
                    )
                  }
                >
                  <img src={remove} alt="Удалить" title="Удалить" />
                </button>
              )}
            </div>
          ))}
      </div>
    </div>
  );
}

export default ImageUploader;
