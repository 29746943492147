// import { Routes, Route,BrowserRouter } from "react-router-dom";
// import axios from 'axios';
import { useEffect, useState } from 'react';
import '../../App.css';
import './ProductPage.css';
import axios from 'axios';
import { BASE_URL } from '../../url';
import CategoryAll from '../../components/CategoryAll/CategoryAll';
import Pagination from '../../components/Pagination/Pagination';
import { Link, useParams } from 'react-router-dom';
import PaginationCategory from '../../components/PaginationCategory/PaginationCategory';

const SubcategoryPage = () => {
  const { catid, subid } = useParams();
  return (
    <>
      {/* <CategoryAll/> */}
      {/* <Pagination cat={cat} sub={sub}/> */}
      <PaginationCategory cat={catid} sub={subid} />
    </>
  );
};

export default SubcategoryPage;
