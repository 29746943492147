import './AdminProductForm.css';
import CategoryCheckboxList from '../../CategoryCheckboxList/CategoryCheckboxList';
import ImageUploader from '../../ImageUploader/ImageUploader';
import { useEffect, useState } from 'react';

function AdminProductForm({
  productData,
  onChangeInput,
  handleCategorySelectChange,
  isValid,
  setIsValid,
  setConvertImg,
}) {
  const [errors, setErrors] = useState({
    name: '',
    article: '',
    brand: '',
  });
  useEffect(() => {
    const newErrors = { ...errors };

    if (productData.categoryIDs.length === 0) {
      newErrors.category = 'Не выбрано ни одной категории';
    } else {
      newErrors.category = '';
    }

    Object.keys(errors).forEach((item) => {
      if (item.toString() === 'category') {
        return;
      }

      if (productData[item].toString().trim().length < 2) {
        newErrors[item] = 'Поле заполнено неверно';
      } else {
        newErrors[item] = '';
      }
    });

    setErrors(newErrors);
    setIsValid(Object.values(newErrors).every((error) => error === ''));
  }, [productData]);
  return (
    <div className="admin-prod-form">
      <p>Название</p>
      <input
        name="name"
        placeholder="Название"
        defaultValue={productData ? productData.name : null}
        type="text"
        onChange={onChangeInput}
      />
      {errors.name && <div className="err-inp">{errors.name}</div>}
      <p>Артикул</p>
      <input
        name="article"
        placeholder="Артикул"
        defaultValue={productData ? productData.article : null}
        type="text"
        onChange={onChangeInput}
      />
      {errors.article && <div className="err-inp">{errors.article}</div>}

      <p>Производитель</p>
      <input
        name="brand"
        placeholder="Производитель"
        defaultValue={productData ? productData.brand : null}
        type="text"
        onChange={onChangeInput}
      />
      {errors.brand && <div className="err-inp">{errors.brand}</div>}

      <p>Цена в Br</p>

      <input
        type="number"
        min="0.01"
        step="0.01"
        name="price"
        onChange={onChangeInput}
        defaultValue={productData ? productData.price : null}
      />

      <p>Кол-во в магазине</p>
      <input
        type="number"
        min="0"
        name="storeQuantity"
        onChange={onChangeInput}
        defaultValue={productData ? productData.storeQuantity : null}
        max={productData ? productData.stockQuantity : null}
      />

      <div className="prod-edit-block">
        <input
          defaultChecked={productData.isActive}
          name="isActive"
          onChange={onChangeInput}
          type="checkbox"
        />
        <p>Товар виден пользователям</p>
      </div>

      <div>
        <p>Категории</p>
        {productData && (
          <div>
            {errors.category && (
              <div className="err-inp">{errors.category}</div>
            )}

            <CategoryCheckboxList
              selectedIds={
                productData.categoryIDs ? productData.categoryIDs : []
              }
              onSelectChange={(newSelectedIds) => {
                handleCategorySelectChange(newSelectedIds);
              }}
            />
          </div>
        )}
      </div>

      <p>Описание</p>
      <textarea
        onChange={onChangeInput}
        name="description"
        defaultValue={productData ? productData.description : null}
      />

      <ImageUploader
        imagesData={productData ? productData.images : []}
        setConvertImg={setConvertImg}
      />
    </div>
  );
}

export default AdminProductForm;
