import axios from 'axios';
import { ClientJS } from 'clientjs';
import { BASE_URL } from '../../../url';

export const send_request = axios.create();

send_request.interceptors.request.use(
  (config) => {
    if (localStorage.getItem('access')) {
      config.headers['Authorization'] =
        'Bearer ' + localStorage.getItem('access');
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

let refreshingFunc = undefined;

async function renewToken() {
  const client = new ClientJS();
  const fingerprint = client.getFingerprint();
  return await axios
    .post(
      `${BASE_URL}admin/authorization/session/refresh`,
      {
        useragent: window.navigator.userAgent,
        fingerprint: fingerprint.toString(),
      },
      { withCredentials: true }
    )
    .then((res) => {
      if (res.status === 200) {
        return res.data.access.token;
      } else {
        throw new Error('invalid refresh');
      }
    })
    .catch((err) => {
      console.log(err);
      return '';
    });
}

function isUnauthorizedError(error) {
  const {
    response: { status, statusText },
  } = error;
  return status === 401;
}

send_request.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    const token = localStorage.getItem('access');

    if (!token || !isUnauthorizedError(error)) {
      return Promise.reject(error);
    }

    try {
      if (!refreshingFunc) refreshingFunc = renewToken();

      const newToken = await refreshingFunc;

      localStorage.setItem('access', newToken);

      try {
        originalRequest.headers.Authorization =
          'Bearer ' + localStorage.getItem('access');
        return await axios.request(originalRequest);
      } catch (innerError) {
        if (isUnauthorizedError(innerError)) {
          throw innerError;
        } else {
          console.log(innerError);
        }
      }
    } catch (err) {
      localStorage.removeItem('access');
      window.location = `${window.location.origin}`;
    } finally {
      refreshingFunc = undefined;
    }
  }
);
