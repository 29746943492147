import './AdminHome.css';
import { Link } from 'react-router-dom';

const AdminHome = () => {
  return (
    <div className="admin-home">
      {/* <Link to={'/admin/all-products'}>Все товары</Link>s */}
      <h2>Меню страниц</h2>
      <Link to="/admin/all-products">Все модели</Link>
    </div>
  );
};

export default AdminHome;
