import { useEffect, useState } from 'react';
import '../../App.css';
import './CategoryPage.css';
import axios from 'axios';
import { BASE_URL } from '../../url';
import { Link, useParams } from 'react-router-dom';
import noImg from '../../assets/noImg.png';
import robot from '../../assets/icons/span.vertical-menu-ico.svg';
import tank from '../../assets/icons/span.vertical-menu-ico-1.svg';
import ship from '../../assets/icons/span.vertical-menu-ico-2.svg';
import plane from '../../assets/icons/span.vertical-menu-ico-3.svg';
import vert from '../../assets/icons/span.vertical-menu-ico-4.svg';
import copter from '../../assets/icons/span.vertical-menu-ico-5.svg';
import auto from '../../assets/icons/span.vertical-menu-ico-6.svg';
import detail from '../../assets/icons/span.vertical-menu-ico-10.svg';
import colobike from '../../assets/icons/span.vertical-menu-ico-8.svg';

const CategoryPage = () => {
  const { catid } = useParams();
  let [products, setProducts] = useState();

  useEffect(() => {
    axios
      .get(BASE_URL + 'categories/' + catid)
      .then((res) => {
        setProducts(res.data.categories);
      })
      .catch((err) => console.log(err));
  }, [catid]);
  console.log(products);
  return (
    <div className="category-block">
      <div>
        {catid == 'mashiny' && (
          <img className="auto-animate" src={auto} alt="auto" />
        )}
        {catid == 'kvadrokoptery' && (
          <img className="auto-animate" src={copter} alt="auto" />
        )}
        {catid == 'vertolety' && (
          <img className="auto-animate" src={vert} alt="auto" />
        )}
        {catid == 'samolety' && (
          <img className="auto-animate" src={plane} alt="auto" />
        )}
        {catid == 'korabli' && (
          <img className="auto-animate" src={ship} alt="auto" />
        )}
        {catid == 'tanki' && (
          <img className="auto-animate" src={tank} alt="auto" />
        )}
        {catid == 'igrushki-i-hobbi' && (
          <img className="auto-animate" src={robot} alt="auto" />
        )}
        {catid == 'zapchasti' && (
          <img className="zap-animate" src={detail} alt="auto" />
        )}
        {catid == 'kolesnyj-transport' && (
          <img className="auto-animate" src={colobike} alt="auto" />
        )}
        {/* {catid=='mashiny'&&<img className='auto-animate' src={auto} alt='auto'/>} */}
      </div>
      {products && (
        <>
          {products.length < 1 ? (
            <>Пока ничего нет</>
          ) : (
            <div className="category-page">
              {products.map((i, ind) => (
                <div className="subcategory" key={ind}>
                  <Link
                    to={
                      i.hasChildren
                        ? '/cat/' + i.slug + '/' + i.id
                        : 'subcat/' + i.slug + '/' + i.id
                    }
                  >
                    <img
                      height="100px"
                      width="160px"
                      src={i.imageUrl}
                      alt={i.name}
                    />
                    {i.name}
                  </Link>
                </div>
              ))}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default CategoryPage;
