import './PreorderForm.css';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { BASE_URL } from '../../url';
import Modal from '../Modal/Modal';
import PhoneInput from '../PhoneInput/PhoneInput';

const PreorderForm = (productItem) => {
  const product = productItem.product;
  let [isSuccessAdd, setSuccessAdd] = useState(false);
  let [statusOrder, setStatusOrder] = useState('');
  const [modalCreateOrderOpen, setModalCreateOrderOpen] = useState(false);

  let [dataUser, setDataUser] = useState();
  let [errorData, setErrorData] = useState({
    email: '',
    fullname: '',
    phoneNumber: '',
  });

  useEffect(() => {
    dataUser && setErrorData(validate(dataUser));
  }, [dataUser]);
  const changeInputUser = (event) => {
    event.persist();
    setDataUser((prev) => {
      return {
        ...prev,
        [event.target.name]: event.target.value,
      };
    });
  };
  const validate = (values) => {
    let errors = {};
    if (!values.fullname) {
      errors.fullname = 'Поле ФИО обязательно для ввода';
    } else if (values.fullname < 2) {
      errors.fullname = 'Поле ФИО должно быть длиннее двух символов';
    }

    if (!values.email) {
      errors.email = 'Поле email обязательно для ввода';
    } else if (!values.email) {
      errors.email = 'Поле email обязательно для ввода';
    } else if (
      /^[\w-\.]+@([\w-]+\.)+[\w-]{1,4}$/.test(values.email) === false
    ) {
      errors.email =
        'Поле email может содержать только буквы латинского алфавита';
    }

    if (!values.phoneNumber) {
      errors.phoneNumber = 'Поле номер телефона обязательно для ввода';
    }

    return errors;
  };
  const onCreateOrder = () => {
    dataUser.products = [{ id: product.id, quantity: 1 }];
    let strPhoneNumber = dataUser.phoneNumber;
    strPhoneNumber = strPhoneNumber.replace(/[^+\d]/g, '');
    dataUser.phoneNumber = strPhoneNumber;
    axios
      .post(BASE_URL + 'preorder', dataUser)
      .then((res) => {
        setModalCreateOrderOpen(false);
        setStatusOrder('Отправлено');
        setSuccessAdd(true);
        setTimeout(() => setSuccessAdd(false), 2000);
        window.location.assign('/success');
      })
      .catch((err) => {
        setStatusOrder('Что-то пошло не так');
        setSuccessAdd(true);
        setTimeout(() => setSuccessAdd(false), 2000);
      });
  };
  return (
    <>
      {isSuccessAdd && <div className="seccess-add">{statusOrder}</div>}
      {product && (
        <Modal
          isOpen={modalCreateOrderOpen}
          onClose={() => setModalCreateOrderOpen(false)}
          content={
            <div>
              <h2>Заявка на заказ</h2>
              <div className="madal-product">
                <div className="modal-product-img">
                  <img
                    src={
                      product.imageUrl
                        ? product.imageUrl
                        : product.imagesUrls[0]
                    }
                    alt={product.name}
                  />
                </div>
                <h3>{product.name}</h3>
              </div>
              <div className="modal-create-order-form">
                <div>ФИО:</div>
                <input
                  name="fullname"
                  type="text"
                  placeholder="ФИО"
                  className="inp"
                  onChange={changeInputUser}
                />
                {errorData.fullname && (
                  <div className="err">{errorData.fullname}</div>
                )}

                <div>Электронная почта:</div>
                <input
                  name="email"
                  type="email"
                  placeholder="Email"
                  className="inp"
                  onChange={changeInputUser}
                />
                {errorData.email && (
                  <div className="err">{errorData.email}</div>
                )}

                <div>Номер телефона:</div>
                <PhoneInput onChange={changeInputUser} />
                {errorData.phoneNumber && (
                  <div className="err">{errorData.phoneNumber}</div>
                )}
                <div>Комментарий</div>
                <textarea
                  name="comment"
                  // className="inp"
                  type="text"
                  // placeholder='Комментарий'
                  onChange={changeInputUser}
                />
              </div>
              <button
                disabled={Object.keys(errorData).length !== 0}
                className="to-cart-btn"
                onClick={onCreateOrder}
              >
                Оформить
              </button>
            </div>
          }
        />
      )}
      <button
        className="to-cart-btn"
        onClick={() => setModalCreateOrderOpen(true)}
      >
        Оформить под заказ
      </button>
    </>
  );
};
export default PreorderForm;
