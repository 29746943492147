import '../static.css';

import chek from '../../../assets/chek.jpg.crdownload';

const Contacts = () => {
  return (
    <div className="static">
      <h2>Адрес и контакты</h2>
      <p>
        <b>
          <br />
          +375(29)577-46-70 mts
          <br />
          +375(44)511-45-13 A1
          <br />
          Почта для безналичных заказов и юр. лиц info@rcgo.by
        </b>
      </p>

      <p>
        <span>Время работы:</span>
        <br />
        Пн-Пт с 10.00 до 19.00 (без перерыва)
        <br />
        Вы можете позвонить нашим менеджерам и получить ответы на все
        интересующие Вас вопросы или оформить заказ, если уже сделали свой
        выбор.
        <br />В любое время Вы можете задать свои вопросы и оформить заказ по
        почте info@rcgo.by или воспользоваться системой заказа на сайте.
        <br />
        Также к Вашим услугам удобный ЧАТ, который появляется сбоку: менеджеры
        обязательно ответят на все вопросы (просим проявить терпение, ответ
        может быть не всегда моментальный из-за большой загруженности)
        <br />
        220053, г.Минск, г.Минск, Нововиленская 38, офис 2
      </p>

      <p>
        <span>ООО "Радиоуправляемые модели"</span>
        <br />
        <span>Зарегистрирован </span>Минским горисполкомом 25 октября 2013 года.
        <br /> <span>УНП: </span>192149079
      </p>
      <p>
        <span>Рс BYN: </span>BY23ALFA30122558490010270000 в ЗАО «Альфа-Банк»,
        220012 г. Минск, г. Минск, ул. Сурганова, 43-47
        <br />
        <span>БИК </span>ALFABY2X
      </p>
      <p>
        Зарегистрирован в торговом реестре Республики Беларусь 20.11.2013
        <br />
        <span>Директор: </span>Нарбутович Андрей Николаевич
      </p>
    </div>
  );
};

export default Contacts;
