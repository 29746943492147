import { useEffect, useState } from 'react';
import './category-all.css';
import axios from 'axios';
import { BASE_URL } from '../../url';
import Accordion from '../Accordion/Accordion';
import { Link } from 'react-router-dom';

const CategoryAll = () => {
  let [categories, setCategories] = useState();
  useEffect(() => {
    axios
      .get(BASE_URL + 'categories/parents')
      .then((res) => {
        setCategories(res.data.categories);
      })
      .catch((err) => console.log(err));
  }, []);
  return (
    <div className="category-all">
      <div className="menu-desctop">
        {categories &&
          categories.map((i, ind) => (
            <Link
              // onClick={() => {
              //   window.location.assign('/cat/' + i.id);
              // }}
              key={ind}
              to={`/cat/${i.slug}/${i.id}`}
            >
              {i.name}
            </Link>
          ))}
      </div>

      <div className="menu-mobile">
        <Accordion
          title={'Каталог'}
          content={
            categories &&
            categories.map((item, index) => (
              <Link
                className="mobile-catalog-category"
                to={`/cat/${item.slug}/${item.id}`}
                key={index}
              >
                {item.name}
              </Link>
            ))
          }
        />
      </div>
    </div>
  );
};

export default CategoryAll;
