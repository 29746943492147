// import { Routes, Route,BrowserRouter } from "react-router-dom";
// import axios from 'axios';
import { useEffect, useState } from 'react';
import '../../App.css';
import './Cart.css';
import axios from 'axios';
import { BASE_URL } from '../../url';
import CategoryAll from '../../components/CategoryAll/CategoryAll';
import Pagination from '../../components/Pagination/Pagination';
import { Link } from 'react-router-dom';
import ReactInputMask from 'react-input-mask';
import noImg from '../../assets/noImg.png';
import { getInfoCart } from '../../store/Actions/Action';
import { useDispatch } from 'react-redux';
import PhoneInput from '../../components/PhoneInput/PhoneInput';

const Cart = () => {
  let [cart, setCart] = useState();
  let [unavailableCart, setUnavailableCart] = useState();
  let [deliveryTypes, setDeliveryTypes] = useState();
  let [paymentTypes, setPaymentTypes] = useState();
  let [status, setStatus] = useState();
  let [isSuccessAdd, setSuccessAdd] = useState(false);
  let [dataUser, setDataUser] = useState();
  let [totalPrice, setTotalPrice] = useState(0);
  const dispatch = useDispatch();
  let [errorData, setErrorData] = useState({
    address: '',
    email: '',
    fio: '',
    index: '',
    phoneNumber: '',
    delivery: '',
    payment: '',
  });

  useEffect(() => {
    getCart();
    axios
      .get(BASE_URL + 'delivery-types')
      .then((res) => {
        setDeliveryTypes(res.data.deliveryTypes);
      })
      .catch((err) => console.log(err));
    axios
      .get(BASE_URL + 'payment-types')
      .then((res) => {
        setPaymentTypes(res.data.paymentTypes);
      })
      .catch((err) => console.log(err));
  }, [status]);
  useEffect(() => {
    dataUser && setErrorData(validate(dataUser));
  }, [dataUser]);

  const createOrder = () => {
    let basc = [];
    let strPhoneNumber = dataUser.phoneNumber;
    strPhoneNumber = strPhoneNumber.replace(/[^+\d]/g, '');
    dataUser.phoneNumber = strPhoneNumber;
    cart.map((i) =>
      basc.push({ id: i.id, quantity: i.quantity, price: i.price })
    );

    const finalOrder = {
      // firstname: dataUser.fio,
      // lastname: dataUser.fio,
      fullname: dataUser.fio,
      email: dataUser.email,
      phoneNumber: dataUser.phoneNumber,
      address: dataUser.address,
      postcode: dataUser.index,
      comment: dataUser.comment,
      deliveryTypeID: +dataUser.delivery,
      paymentTypeID: +dataUser.payment,
      products: basc,
    };
    axios
      .post(BASE_URL + 'order', finalOrder)
      .then((res) => {
        setStatus('Успешно оформлено');
        setCart(null);
        localStorage.removeItem('cart');
        setSuccessAdd(true);
        setTimeout(() => setSuccessAdd(false), 2000);
        dispatch(getInfoCart());
        window.location.assign('/success');
      })
      .catch((err) => {
        console.log(err);
        setStatus('Корзина устарела, попробуйте заново');
        setSuccessAdd(true);
        setTimeout(() => setSuccessAdd(false), 2000);
        dispatch(getInfoCart());

        // window.location.reload();
      });
  };
  const getCart = () => {
    const testingCart = [];
    if (JSON.parse(localStorage.getItem('cart')) === null) {
      localStorage.removeItem('cart');
      setCart(null);
      setUnavailableCart(null);
      return;
    }
    JSON.parse(localStorage.getItem('cart')).forEach((element) => {
      testingCart.push({ id: element.id, quantity: element.quantity });
    });
    if (testingCart.length === 0) {
      return;
    }
    axios
      .post(BASE_URL + 'cart', {
        products: testingCart,
      })
      .then((res) => {
        setCart(res.data.available);
        let allPrice = 0;
        res.data.available.forEach((item) => {
          allPrice += item.price * item.quantity;
        });
        setTotalPrice(allPrice);
        setUnavailableCart(res.data.unavailable);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const changeInputUser = (event) => {
    event.persist();
    setDataUser((prev) => {
      return {
        ...prev,
        [event.target.name]: event.target.value,
      };
    });
  };
  const validate = (values) => {
    let errors = {};
    if (!values.fio) {
      errors.fio = 'Поле ФИО обязательно для ввода';
    } else if (values.fio < 2) {
      errors.fio = 'Поле ФИО должно быть длиннее двух символов';
    }

    if (!values.email) {
      errors.email = 'Поле email обязательно для ввода';
    } else if (!values.email) {
      errors.email = 'Поле email обязательно для ввода';
    } else if (
      /^[\w-\.]+@([\w-]+\.)+[\w-]{1,4}$/.test(values.email) === false
    ) {
      errors.email =
        'Поле email может содержать только буквы латинского алфавита';
    }

    // if (!values.address) {
    //   errors.address = 'Поле адресс обязательно для ввода';
    // }

    if (!values.payment) {
      errors.payment = 'Выберите способ оплаты';
    }

    if (!values.delivery) {
      errors.delivery = 'выберите способ доставки';
    }

    if (!values.phoneNumber) {
      errors.phoneNumber = 'Поле номер телефона обязательно для ввода';
    }

    // if (!values.index) {
    //   errors.index = "Поле почтового индекса обязательно для ввода";
    // }

    return errors;
  };
  return (
    <div className="cart">
      {isSuccessAdd && <div className="seccess-add">{status}</div>}
      <h2>Корзина</h2>
      {/* {unavailableCart && (
        <div>
          <h3>Товары, недоступные к оформлению:</h3>
          <table>
            <tr className="handler-unavailable-table">
              <th>Фото:</th>
              <th>Наименование:</th>
              <th></th>
            </tr>
            {unavailableCart.map((i, ind) => (
              <tr key={ind}>
                <td>
                  <img src={i.imageUrl} alt="i.name" />
                </td>
                <td>{i.name}</td>
                <td
                  className="delete"
                  onClick={() => {
                    unavailableCart.splice(ind, 1);
                    const finalCart = cart && unavailableCart.concat(cart);
                    localStorage.setItem('cart', JSON.stringify(finalCart));
                    getCart();
                    dispatch(getInfoCart());
                  }}
                >
                  x
                </td>
              </tr>
            ))}
          </table>
        </div>
      )} */}
      {cart && cart.length > 0 ? (
        <div>
          <table>
            <tr className="handler-table">
              <th>Фото:</th>
              <th>Наименование:</th>
              <th>Кол-во:</th>
              <th>Стоимость:</th>
              <th></th>
            </tr>
            {cart.map((i, ind) => (
              <tr key={ind}>
                <td>
                  <Link to={'/product/' + i.id}>
                    <img src={i.imageUrl} alt="i.name" />
                  </Link>
                </td>
                <td>
                  <Link to={'/product/' + i.id}>{i.name}</Link>
                </td>
                <td className="quantity-td">
                  <div className="prod-quantity">
                    <div
                      className="pointer"
                      onClick={() => {
                        if (
                          JSON.parse(localStorage.getItem('cart')).find(
                            (item) => item.id === i.id
                          ).quantity > 1
                        ) {
                          cart.find((item) => item.id === i.id).quantity =
                            JSON.parse(localStorage.getItem('cart')).find(
                              (item) => item.id === i.id
                            ).quantity - 1;
                          // const finalCart =
                          //   unavailableCart && cart.concat(unavailableCart);
                          localStorage.setItem('cart', JSON.stringify(cart));
                          if (cart && cart.length > 0) {
                            getCart();
                          }
                          dispatch(getInfoCart());
                        }
                      }}
                    >
                      -
                    </div>
                    <div>
                      {
                        JSON.parse(localStorage.getItem('cart')).find(
                          (item) => item.id === i.id
                        ).quantity
                      }
                    </div>
                    <div
                      className="pointer"
                      onClick={() => {
                        if (
                          JSON.parse(localStorage.getItem('cart')).find(
                            (item) => item.id === i.id
                          ).quantity < i.maxQuantity
                        ) {
                          cart.find((item) => item.id === i.id).quantity =
                            JSON.parse(localStorage.getItem('cart')).find(
                              (item) => item.id === i.id
                            ).quantity + 1;
                          localStorage.setItem('cart', JSON.stringify(cart));
                          getCart();
                          dispatch(getInfoCart());
                        }
                      }}
                    >
                      +
                    </div>
                  </div>
                </td>
                <td className="price-td">{i.price} Br</td>
                <td
                  className="delete"
                  onClick={() => {
                    cart.splice(ind, 1);
                    if (cart.length === 0) {
                      localStorage.setItem('cart', null);
                    } else {
                      localStorage.setItem('cart', JSON.stringify(cart));
                    }
                    getCart();
                    dispatch(getInfoCart());
                  }}
                >
                  x
                </td>
              </tr>
            ))}
          </table>
          <div>Итоговая сумма: {totalPrice} Br</div>
          <h2>Оформление заказа</h2>
          <div className="all-data">
            {/* <fieldset> */}
            <div>
              <div>Способ доставки:</div>

              {errorData.delivery && (
                <div className="err">{errorData.delivery}</div>
              )}

              {deliveryTypes &&
                deliveryTypes.map((item) => (
                  <div>
                    <input
                      onChange={changeInputUser}
                      type="radio"
                      id={item.id}
                      name="delivery"
                      value={item.id}
                    />
                    <label for={item.id}>
                      {item.name}({item.price} р.)
                    </label>
                  </div>
                ))}
            </div>

            {/* </fieldset> */}

            <div className="data">
              <div>Мои данные:</div>
              {/* <label>Имя</label> */}
              <input
                name="fio"
                type="text"
                placeholder="ФИО"
                className="inp"
                onChange={changeInputUser}
              />
              {errorData.fio && <div className="err">{errorData.fio}</div>}

              <input
                name="email"
                type="email"
                placeholder="Email"
                className="inp"
                onChange={changeInputUser}
              />
              {errorData.email && <div className="err">{errorData.email}</div>}

              <input
                name="address"
                type="text"
                placeholder="Адрес"
                className="inp"
                onChange={changeInputUser}
              />
              {/* {errorData.address && (
                <div className="err">{errorData.address}</div>
              )} */}

              {/* <input
          type='text'
          placeholder='Номер телефона'
          onChange={changeInputUser}
          /> */}
              {/* <ReactInputMask
                mask="+375999999999"
                type="tel"
                name="phoneNumber"
                // value={ProfData.phoneNumber}
                onChange={changeInputUser}
                className="inp"
                placeholder="Телефон"
                required
              /> */}
              <PhoneInput onChange={changeInputUser} />
              {errorData.phoneNumber && (
                <div className="err">{errorData.phoneNumber}</div>
              )}

              {/* <input
          onChange={changeInputUser}
          /> */}
              <input
                name="index"
                className="inp"
                type="text"
                placeholder="Почтовый индекс"
                onChange={changeInputUser}
              />
              {errorData.index && <div className="err">{errorData.index}</div>}
            </div>
            <div>
              <div>Способ оплаты:</div>
              {errorData.payment && (
                <div className="err">{errorData.payment}</div>
              )}

              {paymentTypes &&
                paymentTypes.map((item) => (
                  <div>
                    <input
                      onChange={changeInputUser}
                      type="radio"
                      id={item.id}
                      name="payment"
                      value={item.id}
                    />
                    <label for={item.id}>{item.name}</label>
                  </div>
                ))}
            </div>
          </div>
          <div>Комментарий</div>
          <textarea
            name="comment"
            // className='inp'
            type="text"
            // placeholder='Комментарий'
            onChange={changeInputUser}
          />
          <button
            className="to-cart-btn"
            disabled={Object.keys(errorData).length !== 0}
            onClick={createOrder}
          >
            Оформить
          </button>
        </div>
      ) : (
        <h3>Товаров доступных к оформлению нет.</h3>
      )}
    </div>
  );
};

export default Cart;
