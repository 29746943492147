import { useEffect, useState } from 'react';
import './AdminAllProducts.css';
import { BASE_URL } from '../../../url';
import { send_request } from '../Auth/setAuthToken';
import arrow from '../../../assets/ArrowToStock.svg';
import { Link } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import instock from '../../../assets/icons/instock.png';

const AdminAllProducts = () => {
  const [models, setModels] = useState();
  const [totalModels, setTotalModels] = useState(0);
  const [searchText, setSearchText] = useState('');
  const [pageCount, setPageCount] = useState(0);
  const [offset, setOfset] = useState(0);
  const debounce = (func, delay) => {
    let timeout;
    return function (...args) {
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(this, args), delay);
    };
  };

  useEffect(() => {
    send_request
      .get(BASE_URL + 'admin/products/', {
        params: { offset, search: searchText, limit: 10 },
      })
      .then((res) => {
        setModels(res.data.models);
        setTotalModels(res.data.total);
        setPageCount(Math.ceil(res.data.total / 10));
      })
      .catch((err) => console.log(err));
  }, [searchText, offset]);

  const onSearchModel = debounce((e) => {
    setOfset(0);
    setSearchText(e.target.value.trim());
  }, 700);
  return (
    <div className="admin-all-products">
      <div className="all-products-admin-hedline">
        <h2>Все модели ({totalModels})</h2>
        <div>
          <button className="btn">Загрузить товар(Excel)</button>
          <Link to="/admin/add-product" className="btn add-all-prod-btn">
            Добавить товар
          </Link>
        </div>
      </div>
      <div className="all-model-block">
        <input
          placeholder="Поиск по моделям..."
          onChange={onSearchModel}
          className="inp-model-search"
        />
        <div className="all-model-table">
          <div className="all-model-table-headline">
            <span className="id-panel">id</span> Артикул
          </div>

          {models &&
            models.map((model) => (
              <div className="model-block" key={model.id}>
                <div className="model-headline">
                  <span className="id-panel">{model.id}</span>
                  <span>{model.article}</span>
                </div>
                {model.productCards && (
                  <table className="models-producs-table">
                    <tr>
                      <th>id</th>
                      <th className="th-product-name">Название</th>
                      <th>Цена</th>
                      <th>
                        Цена <br />
                        поставщика
                      </th>
                      <th>У поставщика</th>
                      <th>На складе</th>
                      <th></th>
                    </tr>
                    {model.productCards.map((product) => (
                      <tr key={product.id}>
                        <td>{product.id}</td>
                        <td>
                          <Link
                            to={
                              product.isSelfposted
                                ? '/admin/selfmade-product/' + product.id
                                : '/admin/product/' + product.id
                            }
                            className="product-name-block"
                          >
                            <img src={product.imageUrl} alt={product.name} />
                            <div>{product.name}</div>
                          </Link>
                        </td>
                        <td>{product.price} Br</td>
                        <td>
                          {product.originalPrice !== null
                            ? product.originalPrice + '₽'
                            : '-'}
                        </td>
                        <td>
                          {product.stockQuantity !== null
                            ? product.stockQuantity + 'шт.'
                            : '-'}
                        </td>
                        <td>{product.storeQuantity}</td>
                        <td>
                          {product.originalUrl ? (
                            <a href={product.originalUrl} target="_blank">
                              <img
                                title={product.originalUrl}
                                src={arrow}
                                className="arr-to-stock"
                                alt="to stock"
                              />
                            </a>
                          ) : (
                            <img
                              className="instock-img"
                              src={instock}
                              alt="instock"
                            />
                          )}
                        </td>
                      </tr>
                    ))}
                  </table>
                )}
              </div>
            ))}
        </div>
      </div>
      <div>
        {pageCount > 1 && (
          <div className="pagination">
            <ReactPaginate
              previousLabel={'<'}
              nextLabel={'>'}
              pageCount={pageCount}
              onPageChange={(res) => {
                setOfset(res.selected * 10);
                window.scrollTo(0, 100);
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default AdminAllProducts;
