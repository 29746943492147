import Pagination from '../../components/Pagination/Pagination';
import { Link, useParams } from 'react-router-dom';

const Search = () => {
  const { SearchText } = useParams();
  return (
    <>
      <Pagination search={SearchText} />
    </>
  );
};

export default Search;
