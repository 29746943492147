import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { BASE_URL } from '../../url';
import './CategoryCheckboxList.css';

function CategoryCheckboxList({ selectedIds, onSelectChange }) {
  const [categories, setCategories] = useState();
  useEffect(() => {
    axios
      .get(BASE_URL + 'categories/')
      .then((res) => setCategories(res.data.categories))
      .catch((err) => console.log(err));
  }, []);
  const [checkedIds, setCheckedIds] = useState(selectedIds);

  const handleCheckboxChange = (id) => {
    const newCheckedIds = checkedIds.includes(id)
      ? checkedIds.filter((item) => item !== id)
      : [...checkedIds, id];
    setCheckedIds(newCheckedIds);
    onSelectChange(newCheckedIds);
  };

  const renderCategoryList = (categories, level = 0) => {
    return (
      categories &&
      categories.map((category) => {
        const isChecked = checkedIds.includes(category.id);
        return (
          <li key={category.id} style={{ marginLeft: level * 20 }}>
            {!category.children && (
              <input
                type="checkbox"
                id={`category-${category.id}`}
                checked={isChecked}
                onChange={() => handleCheckboxChange(category.id)}
              />
            )}

            <label htmlFor={`category-${category.id}`}>{category.name}</label>
            {category.children && (
              <ul>{renderCategoryList(category.children, level + 1)}</ul>
            )}
          </li>
        );
      })
    );
  };

  return (
    <ul className="select-category-list">{renderCategoryList(categories)}</ul>
  );
}

export default CategoryCheckboxList;
