// import { Routes, Route,BrowserRouter } from "react-router-dom";
// import axios from 'axios';
import { useEffect, useState } from "react";
import "../static.css";

import chek from "../../../assets/chek.jpg.crdownload";

const Opt = () => {
  return (
    <div className="static">
      <h2>Опт</h2>
      <h3>Опт в RCGO</h3>
      <strong>
        Компания RCGO спешит предложить всем магазинам весь обширный каталог
        моделей всех категорий по оптовым ценам! Крупнейший магазин
        радиоуправляемых моделей в Беларуси! Вы без труда сможете подобрать
        оптимальную корзину товара под любой объем продаж и категорию
        покупателей! Мы отправляем заказы по всей Беларуси в любой населенный
        пункт в кротчайшие сроки по минимальным ценам!
      </strong>
      <br />
      <br />
      <strong>
        Почему RCGO? Потому, что только у нас вы сможете выбрать из более чем
        9000 моделей, игрушек, конструкторов, электромобилей, гироскутеров и
        прочих хобби товаров!
      </strong>
      <br />
      <br />{" "}
      <strong>
        Более 4000 автомоделей
        <br />
        Более 600 квадрокоптеров
        <br />
        Более 600 вертолетов
        <br />
        Более 1200 самолетов
        <br />
        Более 300 катеров и яхт
        <br />
        Более 300 танков и танковых боев
        <br />
        Более 80 гироскутеров
        <br />
        Более 600 детских электромобилей
        <br />
        Более 1100 сборных моделей
        <br />
        Более 2300 игрушек , конструкторов, роботов, пазлов и прочих хобби
        товаров!
        <br />и десятки тысячь запчастей, аксессуаров и тюнинга!
      </strong>
      <h3>Начать сотрудничать с RCGO совершенно просто! </h3>
      <p>
        Все что Вам нужно сделать для начала сотрудничества, это
        зарегистрироваться на нашем сайте и отправить на почту info@rcgo.by
        следующую информацию:
        <br />
        1. Наименование юридического лица и год регистрации
        <br />
        2. Город и место размещения (торговый центр / рынок / офис / магазин и
        тд.)
        <br />
        3. Сайт Вашего магазина (если имеется)
        <br />
        4. Логин на нашем сайте.
      </p>
      <p>Далее с Вами свяжется менеджер и обсудит детали сотрудничества.</p>
      <h3>Мы всегда открыты к сотрудничеству! </h3>
    </div>
  );
};

export default Opt;
