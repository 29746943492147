// import { Routes, Route,BrowserRouter } from "react-router-dom";
// import axios from 'axios';
import { useEffect, useState } from 'react';
import '../../App.css';
import './home.css';
import axios from 'axios';
import { BASE_URL } from '../../url';
import CategoryAll from '../../components/CategoryAll/CategoryAll';
import Pagination from '../../components/Pagination/Pagination';
import hero from '../../assets/hero.png';
import hero2 from '../../assets/hero2.jpg';
import hero3 from '../../assets/hero3.jpg';
import hero4 from '../../assets/hero4.jpg';
import hero5 from '../../assets/hero5.jpg';
import hero6 from '../../assets/hero6.jpg';
import Carousel from 'nuka-carousel';
import HomeContent from '../../components/HomeContent/HomeContent';
import { Link } from 'react-router-dom';

const Home = () => {
  return (
    <div className="home-page">
      {/* <CategoryAll/> */}
      <div className="carousel-home">
        <Carousel
          speed={200}
          autoplay={true}
          autoplayInterval={7000}
          disableEdgeSwiping={true}
          wrapAround={true}
          renderCenterLeftControls={false}
          renderCenterRightControls={false}
        >
          <Link to={'https://www.instagram.com/rcgoby/'}>
            <img className="hero-img" src={hero2} alt="hero" />
          </Link>
          <Link to={'https://rcgo.by/search/ft011'}>
            <img className="hero-img" src={hero3} alt="hero" />
          </Link>
          <Link to={'https://rcgo.by/search/space%20rail'}>
            <img className="hero-img" src={hero} alt="hero" />
          </Link>
          <Link to={'https://rcgo.by/subcat/69/'}>
            <img className="hero-img" src={hero4} alt="hero" />
          </Link>
          <Link to={'https://rcgo.by/subcat/43/'}>
            <img className="hero-img" src={hero5} alt="hero" />
          </Link>
          {/* <img className="hero-img" src={hero6} alt="hero" /> */}
        </Carousel>
      </div>
      <HomeContent />

      {/* <Pagination /> */}
    </div>
  );
};

export default Home;
