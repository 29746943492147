import { useEffect, useState } from 'react';
import '../../App.css';
import './pagination.css';
import axios from 'axios';
import { BASE_URL } from '../../url';
import CategoryAll from '../CategoryAll/CategoryAll';
import Pagination from 'react-js-pagination';
import ReactPaginate from 'react-paginate';
import { Link } from 'react-router-dom';
import noImg from '../../assets/noImg.png';
import { getInfoCart } from '../../store/Actions/Action';
import { useDispatch } from 'react-redux';
import Modal from '../../components/Modal/Modal';
import PreorderForm from '../PreorderForm/PreorderForm';
import Select from 'react-select';
import RangeSlider from 'react-range-slider-input';
import 'react-range-slider-input/dist/style.css';

const PaginationCatalog = ({ cat, sub, search }) => {
  let [products, setProducts] = useState();
  let [quantity, setQuantity] = useState(1);
  let [currentProduct, setCurrentProduct] = useState();
  const [modalConfirmAddOpen, setModalConfirmAddOpen] = useState(false);
  let [isSuccessAdd, setSuccessAdd] = useState(false);
  let [pageCount, setPageCount] = useState(1);
  let [activePage, setActivePage] = useState(0);
  let [catt, setCat] = useState(cat ? cat : null);
  let [subb, setSub] = useState(sub ? sub : null);
  let prod = localStorage.getItem('cart')
    ? JSON.parse(localStorage.getItem('cart'))
    : [];
  const dispatch = useDispatch();

  const [filterPrice, setFilterPrice] = useState(null);
  const [priceFilterValue, setPriceFilterValue] = useState(null);
  const [brandFilterValue, setBrandFilterValue] = useState(null);
  const [filterBrands, setFilterBrands] = useState(null);
  useEffect(() => {
    getFilters();
  }, []);
  useEffect(() => {
    getProduct();
  }, [activePage]);
  useEffect(() => {}, [isSuccessAdd]);
  const getProduct = () => {
    axios
      .get(BASE_URL + 'products/', {
        params: {
          // page: activePage,
          // sort: sort,
          offset: 60 * activePage,
          limit: 60,
          category: catt,
          subcategory: subb,
          search: search ? search : null,
          price: priceFilterValue
            ? priceFilterValue[0] + ':' + priceFilterValue[1]
            : null,
          brand: brandFilterValue
            ? brandFilterValue.map((brand) => brand.value).join(',')
            : null,
        },
      })
      .then((res) => {
        setProducts(res.data.products);
        setPageCount(Math.ceil(res.data.total / 60));
      })
      .catch((err) => console.log(err));
  };
  const getFilters = () => {
    axios
      .get(BASE_URL + 'products/filters/', {
        params: { category: sub, search: search ? search : null },
      })
      .then((res) => {
        setFilterPrice(res.data.filters.price);
        const brands = res.data.filters.brands.map((brand) => {
          return {
            value: brand.id,
            label: brand.name,
          };
        });
        setFilterBrands(brands);
        setPriceFilterValue([
          res.data.filters.price.min,
          res.data.filters.price.max,
        ]);
      })
      .catch((err) => console.log(err));
  };
  const addCart = () => {
    if (prod.find((pr) => pr.id === currentProduct.id)) {
      const quantityInCard = prod.find(
        (pr) => pr.id === currentProduct.id
      ).quantity;
      const newCard = prod.map((pr) =>
        pr.id === currentProduct.id
          ? { ...pr, quantity: quantity + quantityInCard }
          : pr
      );
      setModalConfirmAddOpen(false);
      setSuccessAdd(true);
      setTimeout(() => setSuccessAdd(false), 2000);
      localStorage.setItem('cart', JSON.stringify(newCard));
      dispatch(getInfoCart());
    } else {
      prod.push({
        id: currentProduct.id,
        name: currentProduct.name,
        price: currentProduct.price,
        quantity: quantity,
        img: currentProduct.imageUrl ? currentProduct.imageUrl : '',
        article: currentProduct.article,
        availableQuantity: currentProduct.quantity_composition,
      });
      dispatch(getInfoCart());
      setModalConfirmAddOpen(false);
      setSuccessAdd(true);
      setTimeout(() => setSuccessAdd(false), 2000);

      localStorage.setItem('cart', JSON.stringify(prod));
    }
    dispatch(getInfoCart());
  };
  return (
    <div className="pagination-panel">
      <Modal
        isOpen={modalConfirmAddOpen}
        onClose={() => setModalConfirmAddOpen(false)}
        content={
          currentProduct && (
            <div>
              <div className="modal-product-content ">
                <div>
                  <img
                    className="modal-product-img"
                    src={currentProduct.imageUrl}
                    alt={currentProduct.name}
                  />
                </div>
                <div>
                  <h3>{currentProduct.name}</h3>
                  <div className="price-block">
                    {currentProduct.quantity > 0 ? (
                      currentProduct.quantity -
                        (prod.find((pr) => pr.id === currentProduct.id)
                          ? prod.find((pr) => pr.id === currentProduct.id)
                              .quantity
                          : 0) >
                      0 ? (
                        <div className="prod-quantity">
                          <div
                            onClick={() => {
                              quantity > 1 && setQuantity(quantity - 1);
                            }}
                          >
                            -
                          </div>
                          <div>{quantity}</div>
                          <div
                            onClick={() => {
                              quantity <
                                currentProduct.quantity -
                                  (prod.find(
                                    (pr) => pr.id === currentProduct.id
                                  )
                                    ? prod.find(
                                        (pr) => pr.id === currentProduct.id
                                      ).quantity
                                    : 0) && setQuantity(quantity + 1);
                            }}
                          >
                            +
                          </div>
                        </div>
                      ) : (
                        <div>Всё возможное количество товара уже в корзине</div>
                      )
                    ) : (
                      <div>
                        Товара нет в наличии, но вы можете сохранить его в
                        корзине, возможно, он появиться позже!
                      </div>
                    )}
                  </div>
                  <p>Цена: {currentProduct.price} Br</p>
                </div>
              </div>
              <div>
                <p className="modal-qestion-text">Добавить товар в корзину?</p>
                <div className="modal-btns">
                  {currentProduct.quantity -
                    (prod.find((pr) => pr.id === currentProduct.id)
                      ? prod.find((pr) => pr.id === currentProduct.id).quantity
                      : 0) >
                    0 || currentProduct.quantity === 0 ? (
                    <button className="modal-btn-addcart" onClick={addCart}>
                      Добавить
                    </button>
                  ) : (
                    <button className="modal-btn-addcart">
                      Все возможные добавлены
                    </button>
                  )}

                  <button
                    className="modal-btn-addcart"
                    onClick={() => setModalConfirmAddOpen(false)}
                  >
                    Отмена
                  </button>
                </div>
              </div>
            </div>
          )
        }
      />
      {isSuccessAdd && (
        <div className="seccess-add">
          {/* <img src={addcart} alt='добавлено в корзину'/> */}
          Товар добавлен в корзину
        </div>
      )}

      {priceFilterValue && (
        <div className="filters-block">
          <h3>Фильтрация </h3>
          {priceFilterValue && (
            <div className="price-filter">
              <div>
                <p>
                  {priceFilterValue[0]} - {priceFilterValue[1]} Br
                </p>
              </div>
              <RangeSlider
                rangeSlideDisabled={true}
                max={filterPrice.max}
                min={filterPrice.min}
                defaultValue={priceFilterValue}
                onInput={(e) => setPriceFilterValue([e[0], e[1]])}
              />
            </div>
          )}
          <div className="filter-options">
            {filterBrands && (
              <Select
                isMulti={true}
                className="select-brand"
                placeholder="Производители..."
                onChange={(brand) => setBrandFilterValue(brand)}
                options={filterBrands}
              />
            )}
            <button className="btn-set-filters" onClick={getProduct}>
              Подобрать
            </button>
          </div>
        </div>
      )}
      <div className="catalog">
        {products && products.length > 0 ? (
          <>
            {products.map((i, ind) => (
              <div className="product" key={ind}>
                <Link
                  target="_blank"
                  className="product-link"
                  to={'product/' + i.id}
                >
                  <img
                    // height="200px"
                    className="product-img"
                    src={i.imageUrl}
                    alt={i.name}
                  />
                </Link>
                {JSON.parse(localStorage.getItem('cart')) &&
                JSON.parse(localStorage.getItem('cart')).some(
                  (item) => item.id === i.id
                ) ? (
                  <Link className="btn-add-cart" to={'/cart'}>
                    Перейти в корзину
                  </Link>
                ) : i.quantity > 0 ? (
                  <div
                    onClick={() => {
                      setCurrentProduct(i);
                      setModalConfirmAddOpen(true);
                    }}
                    className="btn-add-cart"
                  >
                    Купить
                  </div>
                ) : (
                  <PreorderForm product={i} />
                )}

                <div className="prod-option">
                  <div className="product-title">
                    <Link target="_blank" to={'product/' + i.id}>
                      {i.name}
                    </Link>
                  </div>
                  <div className="product-price">{i.price} Br</div>
                </div>
              </div>
            ))}
          </>
        ) : (
          <>Товаров не найдено</>
        )}
      </div>
      {pageCount > 1 && (
        <div className="pagination">
          <ReactPaginate
            previousLabel={'<'}
            nextLabel={'>'}
            pageCount={pageCount}
            onPageChange={(res) => {
              setActivePage(res.selected);
              window.scrollTo(0, 400);
            }}
          />
        </div>
      )}
    </div>
  );
};

export default PaginationCatalog;
