import { useEffect, useState } from 'react';
import './AdminProductEdit.css';
import { ADMIN_URL } from '../../../url';
import { send_request } from '../Auth/setAuthToken';
import CategoryCheckboxList from '../../../components/CategoryCheckboxList/CategoryCheckboxList';
import Modal from '../../../components/Modal/Modal';
import noImg from '../../../assets/noImg.png';

const AdminProductEdit = () => {
  const productId = window.location.pathname.split('/').pop();
  const [product, setProduct] = useState();
  const [isChangedFields, setIsChangedFields] = useState(false);
  const [productUpdateFields, setProductUpdateFields] = useState({
    storeQuantity: null,
    price: null,
    isPriceFixed: null,
    categoryIDs: null,
    isCategoriesFixed: null,
  });
  useEffect(() => {
    onFetchProduct();
  }, [productId]);

  const onFetchProduct = () => {
    send_request
      .get(ADMIN_URL + 'products/' + productId)
      .then((res) => {
        setProduct(res.data.product);
        console.log(res.data.product);
        setProductUpdateFields({
          storeQuantity: res.data.product.storeQuantity,
          price: res.data.product.price,
          isPriceFixed: res.data.product.isPriceFixed,
          categoryIDs: res.data.product.categoryIDs,
          isCategoriesFixed: res.data.product.isCategoriesFixed,
          name: res.data.product.name,
          description: res.data.product.description,
          article: res.data.product.article,
          brand: res.data.product.brand,
          imageIDs: res.data.product.images.map((item) => item.id),
          isActive: res.data.product.isActive,
        });
      })
      .catch((err) => console.log(err));
  };

  const deepCompare = (a, b) => {
    if (Array.isArray(a) && Array.isArray(b)) {
      return (
        a.length === b.length &&
        a.every((item, index) => deepCompare(item, b[index]))
      );
    } else {
      return a === b;
    }
  };
  useEffect(() => {
    setIsChangedFields(
      Object.entries(productUpdateFields).filter(
        ([key, value]) => value !== null && !deepCompare(value, product[key])
      ).length > 1
    );
  }, [productUpdateFields, product]);

  const handleCategorySelectChange = (newSelectedIds) => {
    setProductUpdateFields({
      ...productUpdateFields,
      categoryIDs: newSelectedIds,
    });
  };
  const onUpdateProduct = () => {
    if (!isChangedFields) {
      return;
    }
    const formData = new FormData();
    formData.append('product', JSON.stringify(productUpdateFields));
    send_request
      .patch(ADMIN_URL + 'products/' + productId, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((res) => onFetchProduct())
      .catch((err) => console.log(err));
  };

  const onInputChange = (event) => {
    const { name, value } = event.target;
    setProductUpdateFields({ ...productUpdateFields, [name]: +value });
  };
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const onDeleteProduct = () => {
    send_request
      .delete(ADMIN_URL + 'products/' + productId)
      .then((res) => window.location.assign('/admin/all-products'))
      .catch((err) => console.log(err));
  };

  return (
    product && (
      <div className="admin-prod-edit">
        <Modal
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          content={
            <div className="delete-prod-modal">
              <p>Удалить товар?</p>
              <div className="delete-prod-modal-btns">
                <button className="btn" onClick={onDeleteProduct}>
                  Удалить
                </button>
                <button className="btn" onClick={handleCloseModal}>
                  Отмена
                </button>
              </div>
            </div>
          }
        />
        <div className="prod-edit-general-info">
          <img
            src={product.images[0] ? product.images[0].url : noImg}
            alt={product.name}
            className="prod-edit-img"
          />
          <div>
            <h3 className="prod-edit-name">{product.name}</h3>
            <p className="prod-edit-description">{product.description}</p>
          </div>
        </div>
        <div className="prod-edit-other-info">
          <div className="prod-edit-block">
            <input
              defaultChecked={product.isActive}
              name="isActive"
              onChange={(e) => {
                setProductUpdateFields({
                  ...productUpdateFields,
                  isActive: e.target.checked,
                });
              }}
              type="checkbox"
            />
            <h4 className="prod-edit-option-headline">
              Товар виден пользователям
            </h4>
          </div>
          <div className="prod-edit-block">
            <h4 className="prod-edit-option-headline">Артикул</h4>
            <span>{product.article}</span>
          </div>
          <div className="prod-edit-block">
            <h4 className="prod-edit-option-headline">Производитель</h4>
            <span>{product.brand}</span>
          </div>
          <div className="prod-edit-block">
            <h4 className="prod-edit-option-headline">Ссылка у нас</h4>
            <a href={product.localUrl}>{product.localUrl.split('/')[2]}</a>
          </div>
          <div className="prod-edit-block">
            <h4 className="prod-edit-option-headline">Ссылка у поставщика</h4>
            <a href={product.originalUrl}>
              {product.originalUrl.split('/')[2]}
            </a>
          </div>
          <div className="prod-edit-block">
            <h4 className="prod-edit-option-headline">В магазине</h4>
            <input
              type="number"
              min="0"
              name="storeQuantity"
              onChange={onInputChange}
              defaultValue={product.storeQuantity}
              max={product.stockQuantity}
            />
          </div>
          <div className="prod-edit-block">
            <h4 className="prod-edit-option-headline">Кол-во у поставщика</h4>
            <span>{product.stockQuantity}</span>
          </div>
          <div className="prod-edit-block">
            <h4 className="prod-edit-option-headline">Цена в Br</h4>
            <input
              type="number"
              min="0.01"
              step="0.01"
              name="price"
              onChange={onInputChange}
              defaultValue={product.price}
            />
            <div>
              <input
                defaultChecked={product.isPriceFixed}
                name="isPriceFixed"
                onChange={(e) => {
                  setProductUpdateFields({
                    ...productUpdateFields,
                    isPriceFixed: e.target.checked,
                  });
                }}
                type="checkbox"
              />
              <label>Зафиксировать цену</label>
            </div>
          </div>
          <div className="prod-edit-block">
            <h4 className="prod-edit-option-headline">Цена у поставщика</h4>
            <span>{product.originalPrice} ₽</span>
          </div>
          <div className="prod-edit-block">
            <h4 className="prod-edit-option-headline">Категории</h4>
            <div>
              <input
                defaultChecked={product.isCategoriesFixed}
                name="isPriceFixed"
                onChange={(e) => {
                  setProductUpdateFields({
                    ...productUpdateFields,
                    isCategoriesFixed: e.target.checked,
                  });
                }}
                type="checkbox"
              />
              <label>Зафиксировать категорию</label>
              <CategoryCheckboxList
                selectedIds={product.categoryIDs}
                onSelectChange={handleCategorySelectChange}
              />
            </div>
          </div>
        </div>

        <div className="prod-edit-btn-block">
          <button
            disabled={!isChangedFields}
            className="btn"
            onClick={onUpdateProduct}
          >
            Обновить
          </button>
          {/* <button onClick={() => setIsModalOpen(true)} className="btn">
            Удалить
          </button> */}
        </div>
      </div>
    )
  );
};

export default AdminProductEdit;
