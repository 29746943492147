import { useEffect, useState } from 'react';
import './AdminAddProduct.css';
import { ClientJS } from 'clientjs';
import axios from 'axios';
import { ADMIN_URL, BASE_URL } from '../../../url';
import visible from '../../../assets/видимый.png';
import notvisible from '../../../assets/скрытый.png';
import { send_request } from '../Auth/setAuthToken';
import { Link } from 'react-router-dom';
import AdminProductForm from '../../../components/AdminSide/AdminProductForm/AdminProductForm';
import Modal from '../../../components/Modal/Modal';

const AdminAddProduct = () => {
  const [product, setProduct] = useState({
    name: '',
    price: 0.01,
    storeQuantity: 0,
    description: '',
    article: '',
    brand: '',
    categoryIDs: [],
    isActive: false,
  });
  const [isValid, setIsValid] = useState(false);
  const [convertImg, setConvertImg] = useState([]);

  useEffect(() => {}, [product, isValid]);

  const onChangeInput = (e) => {
    const { name, value, type, checked } = e.target;

    const inputValue =
      type === 'checkbox' ? checked : type === 'number' ? +value : value;

    setProduct({
      ...product,
      [name]: inputValue,
    });
  };
  const handleCategorySelectChange = (newSelectedIds) => {
    setProduct({
      ...product,
      categoryIDs: newSelectedIds,
    });
  };
  const onAddProduct = () => {
    const formData = new FormData();
    formData.append('product', JSON.stringify(product));
    convertImg.images.map((item) => formData.append('image', item));

    send_request
      .post(ADMIN_URL + 'products/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((res) => {
        window.location.assign('/admin/all-products');
      })
      .catch((err) => console.log(err));
  };

  return (
    <div className="admin-selmade-edit">
      <h2>Добавление товара</h2>
      <AdminProductForm
        productData={product}
        onChangeInput={onChangeInput}
        handleCategorySelectChange={handleCategorySelectChange}
        isValid={isValid}
        setIsValid={setIsValid}
        setConvertImg={setConvertImg}
      />
      <button
        disabled={!isValid}
        className="btn add-prod-btn"
        onClick={onAddProduct}
      >
        Добавить товар
      </button>
    </div>
  );
};

export default AdminAddProduct;
