// import { Routes, Route,BrowserRouter } from "react-router-dom";
// import axios from 'axios';
import { useEffect, useState } from 'react';
import '../../App.css';
import './pagination-category.css';
import axios from 'axios';
import { BASE_URL } from '../../url';
import CategoryAll from '../CategoryAll/CategoryAll';
import Pagination from 'react-js-pagination';
import ReactPaginate from 'react-paginate';
import { Link } from 'react-router-dom';
import noImg from '../../assets/noImg.png';
import delivery from '../../assets/delivery_small.gif';
import sclad from '../../assets/sclad.png';
import { getInfoCart } from '../../store/Actions/Action';
import { useDispatch } from 'react-redux';
import PreorderForm from '../PreorderForm/PreorderForm';
import Select from 'react-select';
import RangeSlider from 'react-range-slider-input';
import 'react-range-slider-input/dist/style.css';

const PaginationCategory = ({ cat, sub, search }) => {
  let [products, setProducts] = useState();
  let [isSuccessAdd, setSuccessAdd] = useState(false);
  let [pageCount, setPageCount] = useState(1);
  let [activePage, setActivePage] = useState(1);
  let [catt, setCat] = useState(cat ? cat : null);
  let [subCatalod, setSub] = useState(sub ? sub : null);
  const dispatch = useDispatch();

  let [sort, setSort] = useState('price:ASC');
  const [filterPrice, setFilterPrice] = useState(null);
  const [priceFilterValue, setPriceFilterValue] = useState(null);
  const [brandFilterValue, setBrandFilterValue] = useState(null);
  const [filterBrands, setFilterBrands] = useState(null);

  const sorts = [
    { value: 'price:ASC', label: 'Сначала дешевле' },
    { value: 'price:DESC', label: 'Сначала дороже' },
  ];

  useEffect(() => {
    getFilters();
  }, []);
  useEffect(() => {
    getProduct();
  }, [activePage, sort]);

  const getProduct = () => {
    axios
      .get(BASE_URL + 'products/', {
        params: {
          sort: sort,
          offset: 30 * (activePage - 1),
          limit: 30,
          category: subCatalod,
          price: priceFilterValue
            ? priceFilterValue[0] + ':' + priceFilterValue[1]
            : null,
          brand: brandFilterValue
            ? brandFilterValue.map((brand) => brand.value).join(',')
            : null,
          // categories: subCatalod,
          search: search ?? search,
        },
      })
      .then((res) => {
        setProducts(res.data.products);
        setPageCount(res.data.total / 30);
      })
      .catch((err) => console.log(err));
  };
  const getFilters = () => {
    axios
      .get(BASE_URL + 'products/filters/', { params: { category: sub } })
      .then((res) => {
        setFilterPrice(res.data.filters.price);
        const brands = res.data.filters.brands.map((brand) => {
          return {
            value: brand.id,
            label: brand.name,
          };
        });
        setFilterBrands(brands);
        setPriceFilterValue([
          res.data.filters.price.min,
          res.data.filters.price.max,
        ]);
      })
      .catch((err) => console.log(err));
  };
  let description =
    products && products.description ? products.description.split('\n') : null;

  const addCart = () => {
    // localStorage.setItem()
  };
  return (
    <div className="category-pagination-panel">
      {isSuccessAdd && (
        <div className="seccess-add">
          {/* <img src={addcart} alt='добавлено в корзину'/> */}
          Товар добавлен в корзину
        </div>
      )}

      <div className="category-catalog">
        {priceFilterValue && (
          <div className="filters-block">
            <h3>Фильтрация </h3>
            {priceFilterValue && (
              <div className="price-filter">
                <div>
                  <p>
                    {priceFilterValue[0]} - {priceFilterValue[1]} Br
                  </p>
                </div>
                <RangeSlider
                  rangeSlideDisabled={true}
                  max={filterPrice.max}
                  min={filterPrice.min}
                  defaultValue={priceFilterValue}
                  onInput={(e) => setPriceFilterValue([e[0], e[1]])}
                />
              </div>
            )}
            <div className="filter-options">
              {filterBrands && (
                <Select
                  isMulti={true}
                  className="select-brand"
                  placeholder="Производители..."
                  onChange={(brand) => setBrandFilterValue(brand)}
                  options={filterBrands}
                />
              )}
              <button className="btn-set-filters" onClick={getProduct}>
                Подобрать
              </button>
            </div>
          </div>
        )}
        {products && products.length > 0 ? (
          <>
            <div className="category-catalog-header">
              <h2 className="category-pagination-title">Каталог</h2>
              <Select
                isSearchable={false}
                className="select-sort"
                onChange={(sort) => setSort(sort.value)}
                defaultValue={sorts[0]}
                options={sorts}
              />
            </div>
            {products.map((i, ind) => (
              <div className="block-category-item" key={ind}>
                <div className="category-product-title">
                  <Link target="_blank" to={'product/' + i.id}>
                    {i.name}
                  </Link>
                </div>
                <div className="category-product">
                  <div>
                    <Link target="_blank" to={'product/' + i.id}>
                      <img
                        height="160px"
                        width="150px"
                        className="category-product-img"
                        src={i.imageUrl}
                        alt={i.name}
                      />
                    </Link>
                  </div>
                  <div className="category-prod-option">
                    <div>
                      <span className="desc-text">Артикул:</span> {i.article}
                    </div>

                    <div className="category-product-price">
                      <span className="desc-text">Цена: </span>
                      {i.price} Br
                    </div>

                    {i.quantity > 0 ? (
                      <>
                        <p className="status-qu">
                          <img src={sclad} alt="есть" />
                          Есть на складе
                        </p>
                        {JSON.parse(localStorage.getItem('cart')) &&
                        JSON.parse(localStorage.getItem('cart')).some(
                          (item) => item.id === i.id
                        ) ? (
                          <Link className="btn-to-cart" to={'/cart'}>
                            Перейти в корзину
                          </Link>
                        ) : (
                          <div
                            onClick={() => {
                              let prod = localStorage.getItem('cart')
                                ? JSON.parse(localStorage.getItem('cart'))
                                : [];
                              if (prod.find((pr) => pr.id === i.id)) {
                              } else {
                                prod.push({
                                  id: i.id,
                                  name: i.name,
                                  price: i.price,
                                  quantity: 1,
                                  img: i.imageUrl,
                                  article: i.article,
                                  availableQuantity: i.quantity,
                                });

                                setSuccessAdd(true);
                                setTimeout(() => setSuccessAdd(false), 2000);
                                localStorage.setItem(
                                  'cart',
                                  JSON.stringify(prod)
                                );
                                dispatch(getInfoCart());
                              }
                            }}
                            className="btn-add-cart"
                          >
                            Купить
                          </div>
                        )}
                      </>
                    ) : (
                      <>
                        <p className="status-qu">Под заказ</p>
                        <PreorderForm product={i} />
                      </>
                    )}
                  </div>
                  <div className="delivery-cat">
                    <Link to={'/delivery'}>
                      <img src={delivery} alt="delivery" />
                    </Link>
                  </div>
                </div>

                {/* {i.images.map((item,index)=>
                <img key={index} src={item.image_url} alt={i.name}/>
              )} */}
              </div>
            ))}
          </>
        ) : (
          <>Товаров не найдено</>
        )}
      </div>
      {pageCount > 1 && (
        <div className="pagination">
          <ReactPaginate
            previousLabel={'<'}
            nextLabel={'>'}
            pageCount={pageCount}
            onPageChange={(res) => {
              setActivePage(res.selected + 1);
              window.scrollTo(0, 400);
            }}
          />
        </div>
      )}
    </div>
  );
};

export default PaginationCategory;
