import { useEffect, useState } from 'react';
import './AdminEntry.css';
import { ClientJS } from 'clientjs';
import axios from 'axios';
import { BASE_URL } from '../../../url';
import visible from '../../../assets/видимый.png';
import notvisible from '../../../assets/скрытый.png';

const AdminEntry = () => {
  const client = new ClientJS();
  const fingerprint = client.getFingerprint();
  let [isHide, setHide] = useState(true);
  const [AuthData, setAuthData] = useState({
    email: '',
    password: '',
    fingerprint: fingerprint.toString(),
    useragent: navigator.userAgent,
  });

  const [entryErr, setEntryErr] = useState(() => {
    return {
      email: '',
      password: '',
    };
  });
  useEffect(() => {
    AuthData && setEntryErr(validate(AuthData));
  }, [AuthData]);

  const changeInputEntry = (event) => {
    event.persist();
    setAuthData((prev) => {
      return {
        ...prev,
        [event.target.name]: event.target.value,
      };
    });
  };

  const validate = (values) => {
    let errors = {};
    if (/^[\w-\.]+@([\w-]+\.)+[\w-]{1,4}$/.test(values.email) === false) {
      errors.email = 'Вы ввели неверную почту';
    }
    return errors;
  };

  const onLogIn = () => {
    axios
      .post(BASE_URL + 'admin/authorization/signin', AuthData, {
        withCredentials: true,
      })
      .then((res) => {
        localStorage.setItem('access', res.data.access.token);
        window.location.assign('/admin/home');
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div className="admin-entry">
      <h2>Вход с систему админстрирования</h2>
      <div>
        <p>Email</p>
        <input
          placeholder="Email"
          className="admin-inp"
          name="email"
          type="email"
          onChange={changeInputEntry}
        />
        <p>Пароль</p>
        <div className="admin-pass">
          <input
            placeholder="Пароль"
            className="admin-inp-pass"
            name="password"
            type={isHide ? 'password' : 'text'}
            onChange={changeInputEntry}
          />
          <img
            onClick={() => setHide(!isHide)}
            className="visible-status"
            src={!isHide ? visible : notvisible}
            alt="visible"
          />
        </div>
      </div>
      {entryErr.email && <div className="err-enter">{entryErr.email}</div>}
      <button
        className="entry-btn"
        onClick={onLogIn}
        disabled={
          Object.keys(entryErr).length !== 0 ||
          AuthData.email.length === 0 ||
          AuthData.password.length === 0
            ? true
            : false
        }
      >
        Вход
      </button>
    </div>
  );
};

export default AdminEntry;
