import './succes.css';
import logo from '../../assets/logo.png';

const SuccesOrder = () => {
  return (
    <div className="succes-order-page">
      <div className="succes-order-content">
        <img src={logo} alt="logo" />
        <h3 className="succes-headline">Успешное оформление</h3>
        <p>Информация отправлена на почту</p>
        <button className="btn" onClick={() => window.location.assign('/')}>
          Вернуться на главную
        </button>
      </div>
    </div>
  );
};

export default SuccesOrder;
