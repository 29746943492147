import { useEffect, useState } from 'react';
import './AdminSelfMadeProdEdit.css';
import { ClientJS } from 'clientjs';
import axios from 'axios';
import { ADMIN_URL, BASE_URL } from '../../../url';
import visible from '../../../assets/видимый.png';
import notvisible from '../../../assets/скрытый.png';
import { send_request } from '../Auth/setAuthToken';
import { Link } from 'react-router-dom';
import AdminProductForm from '../../../components/AdminSide/AdminProductForm/AdminProductForm';
import Modal from '../../../components/Modal/Modal';

const AdminSelfMadeProdEdit = () => {
  const productId = window.location.pathname.split('/').pop();
  const [product, setProduct] = useState();
  const [productUpdate, setProductUpdate] = useState();
  const [isChangedFields, setIsChangedFields] = useState(false);
  const [isValid, setIsValid] = useState(true);

  useEffect(() => {
    onFetchProduct();
  }, [productId]);

  const onFetchProduct = () => {
    send_request
      .get(ADMIN_URL + 'products/' + productId)
      .then((res) => {
        setProduct(res.data.product);
        setProductUpdate(res.data.product);
      })
      .catch((err) => console.log(err));
  };

  const deepCompare = (a, b) => {
    if (Array.isArray(a) && Array.isArray(b)) {
      return (
        a.length === b.length &&
        a.every((item, index) => deepCompare(item, b[index]))
      );
    } else {
      return a === b;
    }
  };

  useEffect(() => {
    if (isValid) {
      setIsChangedFields(
        productUpdate &&
          Object.entries(productUpdate).filter(
            ([key, value]) =>
              value !== null &&
              !deepCompare(value, product[key]) &&
              key !== 'images' &&
              key !== 'imageIDs'
          ).length > 0
      );
    } else {
      setIsChangedFields(false);
    }
  }, [productUpdate, product, isValid]);

  const onChangeInput = (e) => {
    const { name, value, type, checked } = e.target;

    const inputValue =
      type === 'checkbox' ? checked : type === 'number' ? +value : value;

    setProductUpdate({
      ...productUpdate,
      [name]: inputValue,
    });
  };
  const handleCategorySelectChange = (newSelectedIds) => {
    setProductUpdate({
      ...productUpdate,
      categoryIDs: newSelectedIds,
    });
  };
  const onUpdateProduct = () => {
    if (!isChangedFields) {
      return;
    }
    const formData = new FormData();
    console.log(productUpdate);
    console.log(convertImg);
    formData.append('product', JSON.stringify(productUpdate));
    // formData.append('image', convertImg);
    convertImg.images.map((item) => formData.append('image', item));
    send_request
      .patch(ADMIN_URL + 'products/' + productId, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((res) => {
        onFetchProduct();
      })
      .catch((err) => console.log(err));
  };
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const onDeleteProduct = () => {
    send_request
      .delete(ADMIN_URL + 'products/' + productId)
      .then((res) => window.location.assign('/admin/all-products'))
      .catch((err) => console.log(err));
  };

  const [convertImg, setConvertImg] = useState([]);

  useEffect(() => {
    convertImg.imageIDs &&
      setProductUpdate({
        ...productUpdate,
        imageIDs: convertImg.imageIDs,
        images: undefined,
      });
  }, [convertImg, setConvertImg]);

  return (
    product && (
      <div className="admin-selmade-edit">
        <Modal
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          content={
            <div className="delete-prod-modal">
              <p>Удалить товар?</p>
              <div className="delete-prod-modal-btns">
                <button className="btn" onClick={onDeleteProduct}>
                  Удалить
                </button>
                <button className="btn" onClick={handleCloseModal}>
                  Отмена
                </button>
              </div>
            </div>
          }
        />
        <h2>Редактирование товара</h2>
        <AdminProductForm
          productData={product}
          onChangeInput={onChangeInput}
          handleCategorySelectChange={handleCategorySelectChange}
          isValid={isValid}
          setIsValid={setIsValid}
          setConvertImg={setConvertImg}
        />
        <div className="prod-edit-btn-block">
          <button
            disabled={!isChangedFields}
            className="btn"
            onClick={onUpdateProduct}
          >
            Обновить
          </button>
          <button onClick={() => setIsModalOpen(true)} className="btn">
            Удалить
          </button>
        </div>
      </div>
    )
  );
};

export default AdminSelfMadeProdEdit;
