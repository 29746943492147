import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import './index.css';
import App from './App';
// import { Provider } from 'react-redux';
import Home from './pages/Home/Home';
import Delivery from './pages/static/Delivery/Delivery';
import ProductPage from './pages/ProductPage/ProductPage';
import About from './pages/static/About/About';
import Contacts from './pages/static/Contacts/Contacts';
import Service from './pages/static/Service/Service';
import Opt from './pages/static/Opt/Opt';
import Conditions from './pages/static/Conditions/Conditions';
import Xiaomi from './pages/static/Xiaomi/Xiaomi';
import CategoryPage from './pages/CategoryPage/CategoryPage';
import SubcategoryPage from './pages/SubcategoryPage/SubcategoryPage';
import Cart from './pages/Cart/Cart';
import Search from './pages/Search/Search';
import AdminEntry from './pages/AdminSide/AdminEntry/AdminEntry';
import AdminHome from './pages/AdminSide/AdminHome/AdminHome';
import AdminAllProducts from './pages/AdminSide/AdminAllProducts/AdminAllProducts';
import AdminProductEdit from './pages/AdminSide/AdminProductEdit/AdminProductEdit';
import AdminSelfMadeProdEdit from './pages/AdminSide/AdminSelfMadeProdEdit/AdminSelfMadeProdEdit';
import AdminAddProduct from './pages/AdminSide/AdminAddProduct/AdminAddProduct';
import SuccesOrder from './pages/SuccesOrder/SuccesOrder';

export default function Routing() {
  const isAdmin = (component) => {
    if (
      window.location.pathname.slice(0, 7) === '/admin/' &&
      !localStorage.getItem('access')
    ) {
      return window.location.assign('/admin');
    }
    return component;
  };
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />}>
          <Route path="/" element={<Home />} />
          <Route path="/delivery" element={<Delivery />} />
          <Route path="/about" element={<About />} />
          <Route path="/contacts" element={<Contacts />} />
          <Route path="/service" element={<Service />} />
          <Route path="/opt" element={<Opt />} />
          <Route path="/conditions" element={<Conditions />} />
          <Route path="/xiaomi" element={<Xiaomi />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/product/:Prodid" element={<ProductPage />} />
          <Route
            path="/cat/:catname/:catid/subcat/:subcatname/:subid/product/:Prodid"
            element={<ProductPage />}
          />
          <Route
            path="/search/:SearchText/product/:Prodid"
            element={<ProductPage />}
          />
          <Route path="/search/:SearchText" element={<Search />} />
          <Route path="/cat/:catname/:catid" element={<CategoryPage />} />
          <Route
            path="/cat/:catname/:catid/subcat/:subcatname/:subid"
            element={<SubcategoryPage />}
          />
          <Route path="/success" element={<SuccesOrder />} />

          <Route path="/admin" element={<AdminEntry />} />
          <Route path="/admin/home" element={isAdmin(<AdminHome />)} />
          <Route
            path="/admin/all-products"
            element={isAdmin(<AdminAllProducts />)}
          />
          <Route
            path="/admin/product/:id"
            element={isAdmin(<AdminProductEdit />)}
          />
          <Route
            path="/admin/add-product"
            element={isAdmin(<AdminAddProduct />)}
          />
          <Route
            path="/admin/selfmade-product/:id"
            element={isAdmin(<AdminSelfMadeProdEdit />)}
          />

          {/* <Route path='/enter' element={<Enter/>} /> */}
          {/* <Route path='/calendly/:id' element={<CalendlyPage/>} /> */}
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <Provider>
  <Routing />
  // </Provider>
);
