import './AdminHeader.css';
import { Link } from 'react-router-dom';
import logo from '../../../assets/logo.png';
import axios from 'axios';
import { BASE_URL } from '../../../url';
import { ClientJS } from 'clientjs';

function AdminHeader() {
  const client = new ClientJS();
  const fingerprint = client.getFingerprint();

  const onLogOut = () => {
    axios
      .post(
        BASE_URL + 'admin/authorization/session/signout',
        {
          fingerprint: fingerprint.toString(),
          useragent: navigator.userAgent,
        },
        { withCredentials: true }
      )
      .then((res) => {
        localStorage.removeItem('access');
        window.location.assign('/admin');
      })
      .catch((err) => {
        console.log(err);
        localStorage.removeItem('access');
        window.location.assign('/');
      });
  };
  return (
    <div className="admin-header">
      <Link className="admin-header-link" to={'/admin/home'}>
        <img src={logo} className="admin-logo-img" alt="logo" />{' '}
        Админстрирование
      </Link>
      {localStorage.getItem('access') && (
        <div className="log-out-btn" onClick={onLogOut}>
          Выход
        </div>
      )}
    </div>
  );
}

export default AdminHeader;
