// import { Routes, Route,BrowserRouter } from "react-router-dom";
// import axios from 'axios';
import { useEffect, useState } from "react";
import "../static.css";

import chek from "../../../assets/chek.jpg.crdownload";

const Conditions = () => {
  return (
    <div className="static">
      <h2>Условия покупки</h2>
      <h3>Политика конфиденциальности</h3>
      <p>
        Политика конфиденциальности RCGO.by действует в отношении персональных
        данных, предоставляемых клиентами компании, как для оказания различного
        рода услуг, так и осуществления иного взаимодействия, как правило, в
        электронном формате, а также при участии клиентов в акциях компании,
        оказании технической поддержки клиенту и обслуживанию по гарантии.
      </p>
      <p>
        RCGO.by оставляет за собой право вносить изменения и дополнения в
        действующие положения Политики конфиденциальности, при этом данные
        изменения и дополнения будут иметь преимущество над действующими
        положениями Политики. Предоставляя RCGO.by свои персональные данные,
        клиент дает полное согласие на их обработку способами, предусмотренными
        действующими положениями Политики конфиденциальности компании.
      </p>

      <h3>Порядок сбора, хранения и уничтожения персональных данных</h3>
      <p>
        RCGO.by осуществляет сбор персональных данных только с согласия
        пользователя и исключительно для предоставления клиенту сервиса или
        услуги, требующей информации частного характера: покупка в
        интернет-магазине, участие в акциях, гарантийное и сервисное
        обслуживание. Для предотвращения утечки персональных данных клиента
        RCGO.by использует полный спектр мер информационной безопасности
        (технические и организационные).
      </p>
      <p>
        Максимальный срок хранения персональных данных клиента составляет 75 лет
        с момента получения компанией информации частного характера. В других
        случаях компания хранит персональные данные клиента до прекращения своей
        деятельности.
      </p>
      <p>
        Уничтожение, обезличивание или блокировка персональных данных или их
        части клиента производится для исключения возможности дальнейшей
        обработки данной информации.
      </p>
      <p>
        RCGO.by осуществляет сбор персональных данных клиента в следующих
        категориях:
      </p>

      <h3>Техническая информация:</h3>
      <p>
        Время доступа и IP-адрес Источники перехода на Интернет-ресурс компании
        Интернет-страницы, которые посетил пользователь Просмотры рекламных
        баннеров Другая техническая информация, предоставленная браузером
        пользователя Номер телефона клиента (в случае звонка на номера,
        указанные на сайте компании) При регистрации на сайте www.RCGO.by,
        компания осуществляет сбор персональной информации клиента, необходимой
        для аутентификации пользователя/персонализации услуг/доставки
        заказа/обратной связи с клиентом: Фамилия и имя пользователя e-mail
        номер телефона(для уведомления о состоянии заказа и информационной
        рассылки), адрес (в случае доставки товара по какому-либо адресу) пароль
        от личного кабинета, возраст.
      </p>

      <h3>Информация о действиях пользователя:</h3>
      <p>
        Сведения о соглашениях/договорах между клиентом и компанией Информация о
        запросах и направленных в адрес компании обращениях Данные о товарах,
        приобретенных клиентом и услугах, которыми клиент пользовался
        Совершенные платежи и другая финансовая информация, предусмотренная
        законодательством РФ
      </p>

      <h3>Цели обработки персональных данных клиентов RCGO.by:</h3>
      <p>
        Сведения о соглашениях/договорах между клиентом и компанией Информация о
        запросах и направленных в адрес компании обращениях Данные о товарах,
        приобретенных клиентом и услугах, которыми клиент пользовался
        Совершенные платежи и другая финансовая информация, предусмотренная
        законодательством РФ Цели обработки персональных данных клиентов
        RCGO.by: Продажа продукции и предоставление других услуг клиенту Учет
        клиентских пожеланий (при разработке нового продукта или услуги)
        Осуществление обратной связи с клиентом Информирование о статусе заказа
        Информирование о новинках и проводимых акциях (возможно отписать от
        данной рассылки) RCGO.by не предоставляет персональные данные клиентов
        третьим лицам, за исключением случаев: Передачи персональных данных
        подрядным организациям(курьерские службы, транспортные компании,
        агрегаторы служб доставки и пр.) для осуществления доставки заказа до
        пункта выдачи или до адреса указанного в заказе. Получение прямого
        согласия пользователя на передачу его персональных данных третьему лицу
        По требованию правоохранительных органов в соответствии с действующим
        законодательством Слияние или поглощение компании Использование RCGO.by
        файлов Cookie и точечных маркеров Файлы Cookie дают возможность сбора
        следующей информации о клиенте: IP-адрес/операционная система/тип
        браузера/адреса ссылающихся сайтов. Это необходимо для улучшения услуг,
        предоставляемых клиенту компании (сохранение пароля на сайте www.RCGO.by
        при закрытии браузера, анализ трафика и т.д.) Точечные маркеры служат
        для графического отражения на сайте безличной информации о пользователях
        (счетчики посетителей/просмотров на сайте). По своему желанию клиент в
        любой момент может предотвратить отслеживание своих действий, отключив
        Cookies.
      </p>

      <h3>Права пользователей в отношении персональных данных</h3>
      <p>
        Всем клиентам RCGO.by доступна возможность редактирования персональных
        данных в своем личном кабинете. В случае изъявления желания пользователя
        прекратить обработку его персональных данных, клиенту необходимо
        обратиться к нам по электронной почте info@rcgo.by
      </p>
    </div>
  );
};

export default Conditions;
