import React, { useState } from 'react';
import './PhoneInput.css';
import close from '../../assets/close.svg';
import ReactInputMask from 'react-input-mask';
import ruFlag from '../../assets/ru-flag.avif';
import byFlag from '../../assets/flag_Belarus_2.jpg';
import Select from 'react-select';

const PhoneInput = ({ onChange }) => {
  const [selectCountry, setSelectCountry] = useState('by');
  const countries = [
    { value: 'by', label: 'Беларусь', image: byFlag },
    { value: 'ru', label: 'Россия', image: ruFlag },
  ];
  const style = {
    control: (base) => ({
      ...base,
      border: 0,
      boxShadow: 'none',
      padding: '0px',
      margin: '0px',
      height: '10px',
    }),
  };
  return (
    <>
      <div className="phone-input">
        <ReactInputMask
          mask={
            selectCountry === 'by' ? '+375(99)999-99-99' : '+7(999)999-99-99'
          }
          type="tel"
          name="phoneNumber"
          onChange={onChange}
          className="mask-inp-phone"
          placeholder={selectCountry === 'by' ? '+375...' : '+7...'}
          required
        />
        <Select
          styles={style}
          isSearchable={false}
          className="select-country-phone"
          onChange={(country) => setSelectCountry(country.value)}
          defaultValue={countries[0]}
          options={countries}
          formatOptionLabel={(country) => (
            <img
              src={country.image}
              className="country-image"
              alt="country-image"
            />
          )}
        />
      </div>
    </>
  );
};

export default PhoneInput;
