import React from "react";
// import { Transition } from "react-transition-group";
// import { ReactComponent as IconClose } from "./icon-close.svg";
import "./Modal.css";
import close from "../../assets/close.svg";

const Modal = ({ isOpen, onClose, content }) => {
  //   const onWrapperClick = (event) => {
  //     if (event.target.classList.contains("modal-wrapper")) onClose();
  //   };
  return (
    <>
      {/* <div in={isOpen} timeout={350} unmountOnExit={true}> */}
      {/* {(state) => ( */}
      {isOpen && (
        <div className={`modal`}>
          <div className="modal-wrapper">
            <div className="modal-content">
              <button className="modal-close-button" onClick={() => onClose()}>
                <img src={close} alt="close" />
              </button>
              {content}
            </div>
          </div>
        </div>
      )}
      {/* )} */}
      {/* </div> */}
    </>
  );
};

export default Modal;
